body {
  margin:0;
  overflow-x: hidden;
  background: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%) no-repeat center center fixed;
}
.page-container{
  display: flex;
  flex-direction: column;
  flex-flow: column nowrap;
  min-height: 100vh;
  width: 100%;
}
main{
  flex:1
}
.navbar{
  margin: 0 auto;
  display: flex;
  width: 80%;
  background-color:rgba(56, 53, 53);
  justify-content: space-around;
  border-width: 1px;
  border-style: solid;
  box-shadow: 10px 5px 5px seagreen;
}
@media(max-width:410px){
  .navbar{
    justify-content: center;
  }
  
}

.navbar-link {
  font-family: Roboto,Arial;
  display: flex;
  text-align: center;
  height: 40px;
  text-decoration: none;
  align-items: center;
  padding: 0.25rem;
  font-size: 0.8rem;
  font-weight: bolder;
  color: seagreen;
  transition:transform, 200ms ease-in;
}
.navbar-link:hover{
  background-color: lightgreen;
  border-radius: 2px;
}
.site-title {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.site-title a {
  margin: 0 auto;
  font-style: oblique;
  text-decoration: none;
  color: #00FF00;
  top: 50%; 
  right: 50%;
  transform: translate(50%,-50%);
  text-transform: uppercase;
  font-family: verdana;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 1px 1px 1px #919191,
      1px 2px 1px #919191,
      1px 3px 1px #919191,
      1px 4px 1px #919191,
      1px 5px 1px #919191,
      1px 6px 1px #919191,
      1px 7px 1px #919191,
      1px 8px 1px #919191,
      1px 9px 1px #919191,
      1px 10px 1px #919191,
  1px 18px 6px rgba(16,16,16,0.4),
  1px 22px 10px rgba(16,16,16,0.2),
  1px 25px 35px rgba(16,16,16,0.2),
  1px 30px 60px rgba(16,16,16,0.4);
}
.card-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  text-align: center;
  justify-content: center;
 justify-items: center;
}
@media(max-width:750px){
  .card-wrapper{
    grid-template-columns:1fr
  }
}
@media(min-width:751px) and (max-width:1200px){
  .card-wrapper{
    grid-template-columns: 1fr  1fr;
  }
}
.card{
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border:0 solid green;
  box-shadow: 1px 5px 20px green;
  width:300px;
  background-color:lightgray
}
.card-image{
  width: 100%;
  height:270px
}
.card-title{
  font-weight: 600;
  font-size: 20px;
  padding:0.5rem;
  color:#008000
}
.services {
  margin: 40px 0;
  display: block;
  background-color:rgba(56, 53, 53);
  color:seagreen;
  font-size: 1.5rem;
  text-align: center;
  align-items: center;
  width:50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.25rem;
  box-shadow: 10px 5px  5px seagreen;
}
.card:hover{
  transform: scale(1.02);
}
.card .cardButton{
  padding :.5rem;
  font-family: inherit;
  font-weight: bolder;
  font-size: 1rem;
  margin:1rem;
  border:3px solid seagreen;
  background:transparent;
  color: seagreen;
  border-radius: 0.4rem;
  transition: 200ms ease-in, color 
  200ms ease-in;
  cursor: pointer;
}
.card:hover .cardButton{
  background: seagreen;
  color:white;
}
.card .card_description{
  padding: 0 1rem;
  font-size: 1.2rem;
  font-family:Roboto,Arial;
  font-weight: 420;
  color: rgba(56, 53, 53);
}
.email_form{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 60%;
  height: 500px;
  margin:2rem auto;
  border:2px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0 2px 20px green;
  padding:1.5rem;
  background-color: seagreen;
}
@media(max-width:450px){
  .email_form{
    width:80%;
  }
}
label{
  font-size: 1.5rem;
  display: block;
  text-align: left;
  font-weight: bold;
  margin-bottom: -10px
}
textarea{
  resize: none;
}
input ,textarea {
  display: block;
  width: 100%;
  font-weight: bolder;
  border-radius: 0.25rem;
  height: 100px;
  font-size: 1.35rem;
}
input{
  height: 35px;
}
#send_email{
  border: 0;
  background-color: #008000;
  padding:0.5rem;
  color:white;
  margin:1rem 0;
  width:50%;
  margin: 0 auto;
}
#send_email:hover{
  background-color:rgba(29, 194, 29); 
}
.contact_us{
  display: grid;
  background-color:rgba(56, 53, 53);
  color:seagreen;
  font-size: 2rem;
  text-align: center;
  align-items: center;
  width:70%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.25rem;
  box-shadow: 10px 5px  5px seagreen;
}

h2.gallery{
  margin: 40px auto 40px auto;
  display: block;
  background-color:rgba(56, 53, 53);
  color:seagreen;
  font-size: 2rem;
  text-align: center;
  align-items: center;
  width:50%;
  border-radius: 0.25rem;
  box-shadow: 10px 5px  5px seagreen;
}
.main-footer{
  color:aliceblue;
  background-color:rgba(56, 53, 53);
  padding-top: 1em;
  bottom:0;
  width: 100%;
  position: relative;
}
.footContainer{
  align-items: center;
  justify-content: center;
  text-align: center;
}
.info-row{
  display:flex;
  justify-content: space-evenly;
  text-align: left;
}
@media(max-width:420px){
  .info-row{
    flex-direction: column;
  }
}
.info-row ul{
  margin: auto;
}
.list-unstyled li{
  list-style-type:none;
}
.col{
  font-size: 1.1rem;
  color: seagreen;
  margin-bottom: 15px;
}
.footer-header{
  margin-bottom: 20px;
  font-size: 1.3rem;
  text-decoration: underline;
  color: seagreen;
}
.footer-link {
  text-decoration: none;
  transition:transform, 200ms ease-in;
  color: seagreen;
  display: flex;
}
.footer-link:hover{
  background-color: lightgreen;
  border-radius: 1px;
  color:rgb(56, 53, 53);
}
.col-sm{
  font-size: 1rem;
  color: seagreen;
}
.footer-icon{
  position: relative;
  float: left;
  margin-right:5px;
}
.not-found{
  font-size: 3rem;
  color: seagreen;
}












