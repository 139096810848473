.concrete-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
.concrete-service-description{
    display: flex;
    background: url("back2.jpg");
    background-size:100% 100%; 
    background-repeat: no-repeat;
    box-shadow: 0px 15px 10px -15px #111;
    margin: 30px 0 30px 0;
    color:aliceblue
}
.concrete-services{
    flex:1;
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: 2rem;
    font-family: Papyrus;
}
.concrete-icon{
    position: relative;
    float: left;
    margin-right: auto;
}
.drainage-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
}
.drainage-service-description{
    display: flex;
    background: url("back2.jpg");
    background-size:100% 100%; 
    background-repeat: no-repeat;
    box-shadow: 0px 15px 10px -15px #111;
    margin: 30px 0 30px 0;
    color:aliceblue
}
.drainage-services{
    flex:1;
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: 2rem;
    font-family: Papyrus;
}
.drainage-icon{
    position: relative;
    float: left;
    margin-right: auto;
}
.concrete-promise{
    flex: 2;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.concrete-promise p{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1rem;
}
.steps-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}
.steps-card{
    display: flex;
    width:1500px;
    height:800px ;
    justify-content: center;

}
@media(max-width:1100px){
    .steps-card{
        flex-direction: column;
        height: fit-content;
    }
}
.steps-image{
    flex: 1;
    width:500px;
    height: 100%;
}
.steps-images img{
    flex:1;
    width: 500px;
    object-fit: cover;
    box-shadow: 0px 20px 10px -20px #111;
    margin-bottom: 0;
}
.steps-description{
    height: 400px;
    display: table;
    width: 400px;
}
.steps-description p{
    font-weight: 600;
    font-size: 1.3rem;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    background: linear-gradient(to bottom right,#233329,#63D471);
    border-radius:0px 6px 10px 10px;
}
.service-header{
    display: block;
    background-color:rgba(56, 53, 53);
    color:seagreen;
    font-size: 2rem;
    text-align: center;
    align-items: center;
    width:50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.25rem;
    box-shadow: 10px 5px  5px seagreen;
}
.cright-arrow{
    position:absolute;
    right: 15%;
    font-size: 2rem;
    color:#000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  .cleft-arrow{
    position:absolute;
    left: 15%;
    font-size: 2rem;
    color:#000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  .concrete-container{
    display: flex;
    text-align: center;
    justify-content: center;
    
  }
  .concrete-container div{
    width: 400px;
  }
  .concrete-container img{
    height: 100%;
    width: 100%;
  }
  .center-description{
    display: flex;
    flex-direction: column;
    background:url("aesthetic-cement.jpg");
    background-repeat: no-repeat;
    justify-content: center;
    opacity: 0.9;
  }
  @media(max-width:450px){
    .center-description p {
        font-size: 1rem;
    }
    .concrete-container{
     flex-direction: column;
    }
    .concrete-container div{
        height: 300px;
        width: 100%;
      }
  }
  .center-description p{
    text-align:center; 
    font-weight: 400;
    font-size: 1.1rem;
    color: aliceblue;
    margin: 30px 5px 0px 5px;
  }
  .sliderimg{
    width: 100%;
    height: 100px;
    object-fit: cover
  }
  .concrete-promise .drain-question{
    font-size: 2.5rem;
    font-family: Papyrus;
  }
  .about-us-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
}
.aboutus-header{
    font-size: 1.8rem;
    margin-left: 3.5%;
    text-align: left;
    text-decoration:overline underline rgba(56, 53, 53);
    color:seagreen
}
.aboutus-message{
    margin-left: 3.5%;
    width:70%;
    text-align: left;
    color: seagreen;
    font-size: 1.2rem;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;
    background-color:rgba(56, 53, 53);
    border-radius: 1rem;
}

.aboutus-message p{
    padding-left: 10px;
    padding-right: 10px;
}

  



