.products-images-slider{
    .swiper-slide{
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;
        max-width: 50%;
        width: 300px;
        height: 400px;
        border:5px solid rgb(56, 53, 53);

        img{
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @media(max-width: 400px){
        .swiper-slide{
            width: 350px;
            height: 300px;
        }
    }
    .swiper-button-prev{
        left:30;
        color:rgb(255,255,255);
    }
    .swiper-button-next{
        right:30;
        color:rgb(255,255,255)
        }
}